var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-banner',[_c('div',{staticClass:"d-flex justify-space-between pr-4"},[_c('p',{staticClass:"text-h5 font-weight-medium mb-0 d-flex align-center"},[_vm._v(" Salarios ")]),(_vm.searchInArray(_vm.actions, 'Agregar'))?_c('v-btn',{attrs:{"color":"primary","elevation":"0"},on:{"click":function($event){return _vm.modalAdd()}}},[_c('v-icon',{attrs:{"left":"","light":""}},[_vm._v(" mdi-plus ")]),_c('span',{staticClass:"d-none d-sm-flex"},[_vm._v("Agregar Salario")]),_c('span',{staticClass:"d-flex d-sm-none"},[_vm._v("Agregar")])],1):_vm._e()],1)]),_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[(_vm.searchInArray(_vm.actions, 'Ver'))?_c('v-data-table',{attrs:{"headers":_vm.data.headers,"items":_vm.data.data,"item-per-page":5,"search":_vm.search,"loading":_vm.data.load,"loading-text":"Cargando datos..."},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',{staticClass:"text-h5"},[_vm._v(" Lista de Salarios ")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-text-field',{attrs:{"label":"Buscar...","append-icon":"mdi-magnify","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,false,3221905750)},[_c('v-list',[_c('v-list-item',[_c('v-list-item-title',[_c('v-icon',[_vm._v("mdi-file-excel")]),_vm._v(" Descargar CSV ")],1)],1)],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.searchInArray(_vm.actions, 'Editar'))?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"amber"},on:{"click":function($event){_vm.selectPayment(item);
                        _vm.modalUpdate();}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v("Editar")])])]:_vm._e(),(_vm.searchInArray(_vm.actions, 'Eliminar'))?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-5",attrs:{"color":"red"},on:{"click":function($event){_vm.selectPayment(item);
                        _vm.modalDelete();}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v("Eliminar")])])]:_vm._e(),(_vm.searchInArray(_vm.actions, 'Activar/Desactivar'))?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-5",attrs:{"color":item.status == 1
                          ? 'light-blue darken-3'
                          : 'blue-grey darken-1'},on:{"click":function($event){_vm.selectPayment(item);
                        _vm.changeStatus();}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(item.status == 1 ? "mdi-eye-outline" : "mdi-eye-off-outline")+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item.status == 1 ? "Desactivar" : "Activar")+" ")])])]:_vm._e()]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.getPayments()}}},[_vm._v(" Recargar ")])]},proxy:true}],null,true)}):_vm._e()],1)],1)],1)],1),_c('AddPayment',{ref:"mdlAdd",attrs:{"payment":_vm.newPayment,"clean":_vm.cleanAdd}}),_c('UpdatePayment',{ref:"mdlUpdate",attrs:{"payment":_vm.slcPayment,"clean":_vm.cleanUpdate}}),_c('v-dialog',{attrs:{"max-width":"300px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Advertencia")]),_c('v-divider'),_c('v-card-text',{staticClass:"text-center"},[_c('p',{staticClass:"mb-0 mt-4"},[_vm._v(" ¿Está seguro de eliminar este elemento? ")])]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"dark darken-1","text":""},on:{"click":function($event){return _vm.closeDelete()}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":function($event){return _vm.deletePaymentConfirm()}}},[_vm._v(" Eliminar ")]),_c('v-spacer')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }